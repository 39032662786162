@tailwind base;
@tailwind components;
@tailwind utilities;






/* Base style for all toasts */
.toastify-custom .Toastify__toast {
    background-color: #84315E;

    color: #fff;
    /* White text */
    font-size: 16px;
    border-radius: 12px;
    /* Rounded corners for a modern look */
    padding: 15px 25px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    /* 3D shadow effect */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    /* Smooth animation */
    transform: scale(0.95);
}

.toastify-custom .Toastify__toast:hover {
    transform: scale(1);

    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.3);

}

/* Specific styles for different types of toasts */
.toastify-custom .Toastify__toast--success {
    background-color: #28a745;
    /* Green for success */
}

.toastify-custom .Toastify__toast--error {
    background-color: #dc3545;
    /* Red for error */
}

.toastify-custom .Toastify__toast--info {
    background-color: #17a2b8;
    /* Blue for info */
}

.toastify-custom .Toastify__toast--warning {
    background-color: #ffc107;
    /* Yellow for warning */
}

/* Toast container positioning */



/* Adding fade-in animation to the toast */
.toastify-custom .Toastify__toast {
    animation: fadeInUp 0.5s ease-in-out;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
