body{
    font-family: "Work Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
}

.workSans600{
    font-family: "Work Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
}